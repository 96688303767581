import CommonComponents from '@/components';
import http from '@/utils/http';
import { Toast, Slider, NoticeBar, List, Lazyload, CountDown, Circle, Swipe, SwipeItem } from 'vant';
import { trackClick } from '@/utils/track';
import platform from '@/utils/platform';
// import errorNotify from '@/utils/error-handler/index';
import logger from '@/utils/logger';
import VConsole from 'vconsole';
import recorder from '@/utils/common/audio-capture';

import '@/utils/directives';
import dsBridge from 'dsbridge';
import sdk from '@/utils/sdk';

// if (process.env.NODE_ENV !== 'production') new VConsole();

export default {
  install(Vue) {
    // 注册 Vue 原型对象 方法/属性
    Object.defineProperties(Vue.prototype, {
      $sdk: {
        value: sdk,
        writable: false,
      },
      $http: {
        value: http,
        writable: false,
      },
      $track: {
        value: trackClick,
        writable: false,
      },
      $platform: {
        value: platform,
        writable: false,
      },
      $logger: {
        value: logger,
        writable: false,
      },
      $recorder: {
        value: recorder,
        writable: false,
      },
      $dsBridge: {
        value: dsBridge,
        writable: false,
      },
    });
    Vue.use(CommonComponents);
    Vue.use(Toast);
    Vue.use(Slider);
    Vue.use(NoticeBar);
    Vue.use(List);
    Vue.use(CountDown);
    Vue.use(Circle);
    Vue.use(Swipe);
    Vue.use(SwipeItem);
    Vue.use(Lazyload, {
      lazyComponent: true,
    });
    Vue.use(Swipe);
    Vue.use(SwipeItem);
    // Vue.use(() => errorNotify.init(Vue));
  },
};
