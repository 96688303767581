export default [
  {
    path: '/seventh-night',
    name: 'SeventhNight',
    component: () => import('@/views/Activity/SeventhNight/index'),
  },
  {
    path: '/album',
    name: 'Album',
    component: () => import('@/views/Activity/Album/index'),
  },
  {
    path: '/ai-tuning',
    name: 'AITuning',
    component: () => import('@/views/Activity/AITuning/RecordVoice'),
  },
  {
    path: '/wansheng-landing',
    name: 'wansheng-landing',
    component: () => import('@/views/Activity/WanSheng/index'),
  },
  {
    path: '/activity-template-landing',
    name: 'ActivityTemplate',
    component: () => import('@/views/Activity/ActivityTemplate/index'),
  },
  {
    path: '/activity-template-landing-v3',
    name: 'ActivityTemplateV3',
    component: () => import('@/views/Activity/ActivityTemplate/indexV3'),
  },
  {
    path: '/activity-mid-autumn',
    name: 'ActivityMidAutumn',
    component: () => import('@/views/Activity/MidAutumn/index'),
  },
  {
    path: '/activity/national-day',
    name: 'ActivityNationalDay',
    component: () => import('@/views/Activity/NationalDay/index'),
  },
  {
    path: '/activity/national-day/province',
    name: 'ActivityNationalDayProvince',
    component: () => import('@/views/Activity/NationalDay/Province'),
  },
  {
    path: '/activity/spring-festival',
    name: 'ActivitySpringFestival',
    component: () => import('@/views/Activity/SpringFestival/index'),
  },
  {
    path: '/activity/spring-festival/province',
    name: 'ActivitySpringFestivalProvince',
    component: () => import('@/views/Activity/SpringFestival/Province'),
  },
  {
    path: '/activity/farm',
    name: 'ActivityFarm',
    component: () => import('@/views/Activity/Farm/index'),
  },
  {
    path: '/s',
    name: 'LandingMusic',
    component: () => import('@/views/Activity/LandingMusic/index'),
    props: (route) => ({ code: route.params.code }),
  },
];
